export type sizesType = {
  maxWidth: string;
  xxxbig: string;
  xxbig: string;
  xbig: string;
  big: string;
  xxlarge: string;
  xlarge: string;
  large: string;
  semilarge: string;
  caption: string;
  semicaption: string;
  medium: string;
  xmedium: string;
  normal: string;
  small: string;
  xsmall: string;
  micro: string;
};

export const sizes: sizesType = {
  maxWidth: '1262px',
  xxxbig: '100px',
  xxbig: '100px',
  xbig: '80px',
  big: '72px',
  xxlarge: '60px',
  xlarge: '48px',
  large: '40px',
  semilarge: '32px',
  caption: '24px',
  semicaption: '22px',
  medium: '20px',
  xmedium: '18px',
  normal: '16px',
  small: '14px',
  xsmall: '12px',
  micro: '10px',
};
