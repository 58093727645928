import { SVGProps } from 'react';

export const CalculatorDarkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={props.width || '100%'}
    height={props.height || 'auto'}
    fill="none"
    {...props}
  >
    <path
      fill="#757575"
      fillRule="evenodd"
      d="M27.397 1.377a4 4 0 0 1 1.171 2.828v24a4 4 0 0 1-4 4h-16a4 4 0 0 1-4-4v-24a4 4 0 0 1 4-4h16a4 4 0 0 1 2.829 1.172Zm-10.088 17.72a1.333 1.333 0 1 0-1.481 2.216 1.333 1.333 0 0 0 1.481-2.216Zm-1.85-5.633a1.334 1.334 0 1 0 2.219 1.482 1.334 1.334 0 0 0-2.218-1.482Zm7.816 12.034a1 1 0 0 0-.707-.293h-7.333a1 1 0 1 0 0 2h7.333a1 1 0 0 0 .707-1.707Zm-1.447-4.184a1.334 1.334 0 1 0 1.481-2.218 1.334 1.334 0 0 0-1.482 2.218Zm0-6a1.334 1.334 0 1 0 1.481-2.218 1.334 1.334 0 0 0-1.482 2.218Zm-12 6a1.333 1.333 0 1 0 1.481-2.217 1.333 1.333 0 0 0-1.481 2.217Zm1.849 5.632a1.333 1.333 0 1 0-2.217-1.482 1.333 1.333 0 0 0 2.217 1.482Zm-1.85-11.632a1.333 1.333 0 1 0 1.482-2.217 1.333 1.333 0 0 0-1.481 2.217ZM24.569 2.872h-16a1.333 1.333 0 0 0-1.333 1.333v4.333a.333.333 0 0 0 .333.334h18a.333.333 0 0 0 .334-.334V4.205a1.333 1.333 0 0 0-1.334-1.333Zm-1.966 4.702c-.187.187-.44.294-.704.297a1.013 1.013 0 0 1-1-1v-2a1 1 0 0 1 2 0v2c-.003.264-.11.517-.296.703Z"
      clipRule="evenodd"
    />
  </svg>
);
