import styled, { css } from 'styled-components';
import { Icons } from '../../icons';
import { MathComponent } from 'mathjax-react';
import { t } from 'i18next';
import { useRef, useEffect, useState } from 'react';
import { isMathMLEmpty } from '../../utils/is-mathml-empty';
import { InnerHtml } from '../inner-html';

type Props = {
  content: string;
  onClick?: () => void;
  isPressed?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  hasCalculatorSolution: boolean;
  operationContent: string;
} & React.HTMLAttributes<HTMLButtonElement>;

const getBackgroundColor = (
  isPressed: boolean | undefined,
  hasCalculatorSolution: boolean,
  theme: any,
) => {
  if (hasCalculatorSolution) {
    return 'transparent';
  }
  return isPressed ? theme.colors.grey['05'] : theme.colors.white;
};

const StyledResultInput = styled.div<{
  isPressed?: boolean;
  hasCalculatorSolution: boolean;
}>`
  ${({ theme, isPressed, hasCalculatorSolution }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 135px;
    height: 64px;
    overflow: hidden;
    border-radius: ${theme.borders.radius.md};
    cursor: pointer;
    background-color: ${getBackgroundColor(
      isPressed,
      hasCalculatorSolution,
      theme,
    )};
    position: relative;

    :hover {
      background-color: ${isPressed
        ? theme.colors.grey['05']
        : theme.colors.grey['07']};
    }

    @media (max-width: ${theme.breakpoints.xl}) {
      width: 126px;
      height: 60px;
    }
    @media (max-width: ${theme.breakpoints.lg}) {
      width: 108px;
      height: 51px;
    }
  `}
`;

const ErrorMessage = styled.div`
  width: 135px;
  font-family: ${({ theme }) => theme.typography.cmr10};
  font-size: ${({ theme }) => theme.sizes.semicaption};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.red['01']};
  text-align: center;
  font-style: normal;
  font-weight: 400;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: ${({ theme }) => theme.sizes.xmedium};
    line-height: 22px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.sizes.normal};
    line-height: 18px;
  }
  span {
    font-family: ${({ theme }) => theme.typography.regular};
  }
`;

const MathWrapper = styled.div<{ isOverflowing: boolean }>`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ isOverflowing }) =>
    isOverflowing &&
    css`
      mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 70%,
        rgba(0, 0, 0, 0) 100%
      );
      -webkit-mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 70%,
        rgba(0, 0, 0, 0) 100%
      );
    `}

  .MathJax {
    font-family: ${({ theme }) => theme.typography.cmr10};
    font-size: ${({ theme }) => theme.sizes.caption};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey['01']};
    @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
      font-size: ${({ theme }) => theme.sizes.semicaption};
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-size: ${({ theme }) => theme.sizes.xmedium};
    }
  }
`;

export function ResultInput({
  onClick,
  content,
  isPressed,
  isLoading,
  isError,
  hasCalculatorSolution,
  operationContent,
}: Props) {
  const LoadingIcon = Icons['LoadingDots'];
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const hasOperationContent = !isMathMLEmpty(operationContent);

  const checkOverflow = () => {
    if (contentRef.current) {
      setIsOverflowing(
        contentRef.current.scrollWidth > contentRef.current.clientWidth,
      );
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    const observer = new MutationObserver(checkOverflow);
    if (contentRef.current) {
      observer.observe(contentRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      window.removeEventListener('resize', checkOverflow);
      observer.disconnect();
    };
  }, [content]);

  const getContent = () => {
    if (isLoading) {
      return <LoadingIcon />;
    }
    if (isError && hasOperationContent) {
      return (
        <ErrorMessage>
          <InnerHtml text={t('calculator.syntaxError')} />
        </ErrorMessage>
      );
    }
    return (
      <MathWrapper ref={contentRef} isOverflowing={isOverflowing}>
        <MathComponent mathml={content || ''} />
      </MathWrapper>
    );
  };

  return (
    <StyledResultInput
      aria-label="Result"
      role="button"
      onClick={onClick}
      isPressed={isPressed}
      hasCalculatorSolution={hasCalculatorSolution}
      data-testid="result-input"
    >
      {getContent()}
    </StyledResultInput>
  );
}
