import { SVGProps } from 'react';

export const DualNumberArrowsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 49 46" 
    width={props.width || '100%'}
    height={props.height || 'auto'}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeWidth={2}
      d="M38.643 34.35a16.004 16.004 0 0 0 0-22.628M10.359 11.72a16.001 16.001 0 0 0 0 22.628"
    />
    <path
      fill="#757575"
      d="M35.656 38.25a.866.866 0 0 1-.912-.913l.236-4.317a.866.866 0 0 1 1.477-.565l4.081 4.08a.866.866 0 0 1-.565 1.478l-4.317.236ZM13.346 7.82a.866.866 0 0 1 .912.913l-.236 4.317a.866.866 0 0 1-1.477.566L8.464 9.534a.866.866 0 0 1 .565-1.477l4.317-.236Z"
    />
  </svg>
);
