import styled from 'styled-components';

type Props = {
  text?: string;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Wrapper = styled.div`
  p {
    margin: 0;
  }
`;

export function InnerHtml({
  text,
  className,
  ...rest
}: Props): JSX.Element | null {
  return text ? (
    <Wrapper
      className={className}
      dangerouslySetInnerHTML={{ __html: text }}
      {...rest}
    />
  ) : null;
}
