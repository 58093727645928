import { SVGProps } from 'react';
export const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="29"
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <rect
        x="23.1992"
        y="3.17188"
        width="2.88889"
        height="28.8889"
        transform="rotate(45 23.1992 3.17188)"
        fill="#333333"
      />
      <rect
        width="2.88889"
        height="28.8889"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 4.80078 3.17188)"
        fill="#333333"
      />
    </g>
  </svg>
);
