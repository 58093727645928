import styled, { css } from 'styled-components';
import React, {
  useState,
  useEffect,
  useRef,
  ReactElement,
  cloneElement,
} from 'react';
import ReactDOM from 'react-dom';

export const TOOLTIP_DELAY = 500; // In milliseconds
const TOOLTIP_ANIMATION_DURATION = `${TOOLTIP_DELAY / 1000}s`;
export const TOOLTIP_VISIBLE_DURATION = 1600;

type TooltipProps = {
  content: string;
  container?: React.ReactNode;
  trigger?: 'hover' | 'click';
  triggerComponent?: ReactElement;
  marginOffset?: number;
  align?: 'center' | 'left';
  disableTooltip?: boolean;
};

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const TooltipText = styled.div<{
  isVisible: boolean;
  top: number;
  left: number;
  isRendered: boolean;
}>`
  ${({ theme, isVisible, top, left, isRendered }) => css`
    height: 3.375rem;
    width: max-content;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 500;
    padding: 0.75rem 1.5rem;
    background-color: ${theme.colors.grey['01']};
    color: white;
    text-align: center;
    border-radius: 1rem;
    position: fixed;
    z-index: 1000;
    top: ${top}px;
    left: ${left}px;
    transform: translateX(-50%) translateY(${isVisible ? '0' : '100%'});
    opacity: ${isVisible ? '1' : '0'};
    visibility: ${isRendered ? 'visible' : 'hidden'};
    transition:
      opacity 1s ease-in-out,
      transform ${TOOLTIP_ANIMATION_DURATION} ease-in-out;
  `}
`;

export function Tooltip({
  content,
  container,
  trigger = 'hover',
  triggerComponent,
  marginOffset = 10,
  align = 'center',
  disableTooltip = false,
}: TooltipProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const triggerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isVisible) {
      setIsRendered(true);
    } else {
      const timer = setTimeout(() => {
        setIsRendered(false);
      }, TOOLTIP_DELAY);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  const makeItVisible = () => {
    if (disableTooltip) return;
    setIsVisible(true);

    setTimeout(() => {
      setIsVisible(false);
    }, TOOLTIP_VISIBLE_DURATION);
  };

  const handleMouseEnter = () => {
    if (trigger === 'hover' && !disableTooltip) {
      updateTooltipPosition();
      makeItVisible();
    }
  };

  const handleMouseLeave = () => {
    if (trigger === 'hover' && !disableTooltip) {
      setIsVisible(false);
    }
  };

  const handleClick = () => {
    if (trigger === 'click' && !disableTooltip) {
      updateTooltipPosition();
      makeItVisible();
    }
  };

  const updateTooltipPosition = () => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      const triggerHeight = rect.height;
      const top = rect.top + window.scrollY - triggerHeight - marginOffset;
      const left =
        align === 'center'
          ? rect.left + rect.width / 2 + window.scrollX
          : rect.left + window.scrollX;
      setTooltipPosition({ top, left });
    }
  };

  const customTrigger = triggerComponent
    ? cloneElement(triggerComponent, {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onClick: (event: React.MouseEvent) => {
          handleClick();
          triggerComponent.props.onClick?.(event);
        },
      })
    : null;

  return (
    <>
      <TooltipContainer
        ref={triggerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {customTrigger || container || <span>Tooltip Trigger</span>}
      </TooltipContainer>

      {ReactDOM.createPortal(
        <TooltipText
          isVisible={isVisible}
          isRendered={isRendered}
          top={tooltipPosition.top}
          left={tooltipPosition.left}
        >
          {content}
        </TooltipText>,
        document.body,
      )}
    </>
  );
}
