import { Icons } from '../../icons';
import { t } from 'i18next';
import styled from 'styled-components';
import { CalculatorOperation } from '../calculator-operation';
import { CalculatorResult } from '../calculator-result';
import { isMathMLEmpty } from '../../utils/is-mathml-empty';
type Props = {
  onResultClick: () => void;
  onSwitchModeClick: () => void;
  operationContent: string;
  resultContent: string;
  isLoading?: boolean;
  isError?: boolean;
  hasCalculatorSolution: boolean;
  isAnimatingDualButton: boolean;
  isDecimalNumber: boolean;
};
const CalculatorInputContainer = styled.div<{ hasCalculatorSolution: boolean }>`
  width: 460px;
  height: 80px;
  background-color: ${({ hasCalculatorSolution }) =>
    hasCalculatorSolution ? 'transparent' : 'rgba(255, 255, 255, 0.5)'};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 4px 4px 16px;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 423px;
    height: 74px;
    padding: 4px 4px 4px 14px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 360px;
    height: ${({ theme }) => theme.sizes.xxlarge};
    padding: 2px 2px 2px 12px;
  }
`;
const PlaceholderCalculator = styled.div`
  color: ${({ theme }) => theme.colors.grey['02']};
  text-align: center;
  font-family: ${({ theme }) => theme.typography.regular};
  font-size: ${({ theme }) => theme.sizes.semicaption};
  line-height: 30px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: ${({ theme }) => theme.sizes.xmedium};
    line-height: 26px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.sizes.normal};
    line-height: 22px;
  }
`;
const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 30px;
    height: 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 24px;
    height: 24px;
  }
`;
const CalculatorIcon = Icons['CalculatorDarkIcon'];
const CalculatorSvg = styled(CalculatorIcon)`
  width: 32px;
  height: 32px;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 30px;
    height: 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 24px;
    height: 24px;
  }
`;
export function CalculatorInput({
  operationContent,
  onResultClick,
  onSwitchModeClick,
  resultContent,
  isLoading,
  isError,
  hasCalculatorSolution,
  isAnimatingDualButton,
  isDecimalNumber,
}: Props) {
  const hasContent = !isMathMLEmpty(operationContent);

  return (
    <CalculatorInputContainer hasCalculatorSolution={hasCalculatorSolution}>
      <IconWrapper>
        {!hasCalculatorSolution && (
          <CalculatorSvg data-testid="calculator-svg" />
        )}
      </IconWrapper>
      {operationContent && hasContent ? (
        <>
          <CalculatorOperation operationContent={operationContent} />
          <CalculatorResult
            onResultClick={onResultClick}
            onSwitchModeClick={onSwitchModeClick}
            resultContent={resultContent}
            isLoading={isLoading}
            isAnimatingDualButton={isAnimatingDualButton}
            isDecimalNumber={isDecimalNumber}
            isError={isError}
            hasCalculatorSolution={hasCalculatorSolution}
            operationContent={operationContent}
          />
        </>
      ) : (
        <PlaceholderCalculator>
          {t('calculator.initialPlaceholder')}
        </PlaceholderCalculator>
      )}
    </CalculatorInputContainer>
  );
}
