import styled, { css } from 'styled-components';
import { Icons } from '../../icons';

type Props = {
  isPressed?: boolean;
  onClick: () => void;
} & React.HTMLAttributes<HTMLButtonElement>;

export const StyledCalculatorButton = styled.button<{ isPressed?: boolean }>`
  svg {
    width: 28px;
    height: 28px;
  }
  width: 52px;
  height: 52px;
  padding: 12px;

  ${({ theme, isPressed }) => {
    return css`
      background-color: ${isPressed
        ? theme.colors.grey['05']
        : theme.colors.white};
      box-shadow: ${!isPressed
        ? `0px 1.4px 1.4px 0px ${theme.colors.grey['08']}`
        : 'none'};
      display: flex;
      align-items: center;
      z-index: 2;
      justify-content: center;
      border-radius: ${theme.borders.radius.md};
      cursor: pointer;
      border: none;

      &:hover {
        background-color: ${isPressed
          ? theme.colors.grey['05']
          : theme.colors.grey['07']};
      }
    `;
  }}
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    svg {
      width: 24px;
      height: 24px;
    }
    width: 44px;
    height: 44px;
    padding: 10px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    svg {
      width: 20px;
      height: 20px;
    }
    width: 36px;
    height: 36px;
    padding: 8.3px;
  }
`;

export function CalculatorButton({ isPressed, onClick }: Props) {
  const CalculatorIcon = Icons['CalculatorIcon'];
  return (
    <StyledCalculatorButton
      aria-label="Calculator"
      isPressed={isPressed}
      onClick={onClick}
    >
      <CalculatorIcon />
    </StyledCalculatorButton>
  );
}
