import { lazy, Suspense } from 'react';
import { useActivityContext } from '@web-contents/shared';

const Web = lazy(
  // eslint-disable-next-line @nx/enforce-module-boundaries
  () => import('../../libs/activities/web/src/components/web-activity'),
);

const Unity = lazy(
  // eslint-disable-next-line @nx/enforce-module-boundaries
  () => import('../../libs/activities/unity/src/lib/unity-activity'),
);

const LazyRenderer = () => {
  const {
    activityParameters: { type },
  } = useActivityContext();

  const ActivityToRender = ({ type }: { type: string }): JSX.Element => {
    const apiSolverUrl = import.meta.env.VITE_API_SOLVER;
    switch (type) {
      case 'web':
        return <Web apiSolverUrl={apiSolverUrl} />;
      case 'unity':
        return <Unity />;
      default:
        return <div>No type choosen</div>;
    }
  };

  // TODO Loading component
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ActivityToRender type={type} />
    </Suspense>
  );
};

export default LazyRenderer;
