export function isMathMLEmpty(mathMLString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(mathMLString, 'application/xml');
  const mathElement = doc.documentElement;

  // Check if the math element has no child nodes or no meaningful content
  return (
    mathElement.tagName === 'math' &&
    mathElement.children.length === 0 &&
    !mathElement.textContent?.trim()
  );
}
