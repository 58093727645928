import { SVGProps } from 'react';

export const EmptyDualNumberIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29 20" 
    width={props.width || '100%'} 
    height={props.height || 'auto'}
    fill="none"
    {...props}
  >
    <path fill="#fff" d="M.702.033h28v19h-28z" />
    <rect
      width={21.596}
      height={16.038}
      x={3.905}
      y={1.514}
      fill="#757575"
      rx={2.673}
    />
  </svg>
);
