import { SVGProps } from 'react';
export const LoadingDots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Loading_InfiniteLoop">
      <rect
        id="C1"
        x="4.5"
        y="18"
        width="4"
        height="3.99903"
        rx="1.99951"
        fill="#333333"
      />
      <rect
        id="C2"
        x="12.5"
        y="18"
        width="4"
        height="3.99903"
        rx="1.99951"
        fill="#333333"
      />
      <rect
        id="C3"
        x="20.5"
        y="18"
        width="4"
        height="3.99903"
        rx="1.99951"
        fill="#333333"
      />
    </g>
  </svg>
);
