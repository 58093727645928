import {
  ActivityParameters,
  ActivityState,
  Payload,
  RegionParameters,
  RequestState,
  UserParameters,
  DevParameters,
} from './types';

const extractPathSegments = (url: string): string[] => {
  const urlObj = new URL(url);
  const path = urlObj.pathname;
  const segments: string[] = path
    .split('/')
    .filter((segment) => segment.length > 0);
  return segments;
};

const getBuildLogValue = (url: string): boolean => {
  const urlObj = new URL(url);
  return urlObj.searchParams.get('buildlog') === 'true';
};

const segments = extractPathSegments(window.location.href);
const sceneFromUrlPath = segments.length > 2 ? segments[2] : 'undefined';
const typeFromUrlPath = segments.length > 0 ? segments[0] : 'undefined';
const packFromUrlPath = segments.length > 3 ? segments[3] : '0';
const buildlog = getBuildLogValue(window.location.href);

export const defaultActivityParameters: ActivityParameters = {
  id: '23bb6f65-6de7-4992-821a-5b2d237e7f55',
  type: typeFromUrlPath,
  codename: 'undefined',
  description: 'undefined',
  sceneName: sceneFromUrlPath,
  pack: packFromUrlPath,
  minDuration: 60000,
  maxDuration: 180000,
  minStatements: 1200,
  buildlog: buildlog,
  sessionEntity: {
    activities: [
      {
        codename: '',
        is_motor: false,
        max_duration: 180000,
        min_duration: 60000,
        min_statements: 1200,
        pack: Number(packFromUrlPath),
        scene_name: sceneFromUrlPath,
        variation: Number(packFromUrlPath),
      },
    ],
  },
};

export const defaultUserParameters: UserParameters = {
  platformUrl: 'https://innovamat-platform.pre.innovamat.cloud',
  solverUrl: 'https://solver.pre.innovamat.cloud',
  Tokens: {
    AccessToken: 'Default fake token',
    RefreshToken: 'Default fake token',
    IdToken: 'Default fake token',
    clientID: 'innovamat_classroom_manager',
  },
  language: 'es',
  userToken: '',
  userId: '1',
  stage: segments.length > 1 ? segments[1] : 'PRIMARIA',
};

export const defaultRegionParameters: RegionParameters = {
  clock: '24h',
  currency: 'EUR',
  decimalSeparator: ',',
  metricSystem: 'international',
  shortDivisionSign: ':',
  shortMultiplicationSignCollection: {
    primary: '×',
    secondary: '⋅',
  },
  temperature: 'C',
  thousandSeparator: ' ',
  verticalDivisionAlgorithm: 'international',
  verticalMultiplicationPosition: 'left',
  verticalSubtractionPosition: 'left',
  verticalSumPosition: 'left',
  decimalSeparatorAudio: 'S4665',
  verticalOperationsParameters: {
    verticalDivisionAlgorithm: 'international',
    verticalMultiplicationPosition: 'left',
    verticalSubtractionPosition: 'left',
    verticalSumPosition: 'left',
  },
  shortMultiplicationSign: '×',
  numberWriting: 'detailed',
  dataOrigin: 'applet-web-devtool',
};

export const defaultDevParameters: DevParameters = {
  actions: {
    nothing: () => {},
  },
  isDevelopment: true,
  buildLogUrl: undefined,
};

export const defaultPayload: Payload = {
  activityParameters: defaultActivityParameters,
  regionParameters: defaultRegionParameters,
  userParameters: defaultUserParameters,
  solverUrl: 'https://solver.pre.innovamat.cloud',
};

export const defaultActivityState: ActivityState = {
  totalStatements: 0,
  title: 'Default Title',
  isCalculatorAvailable: false,
};

export const defaultRequestState: RequestState = {
  slowRequest: false,
};
