import { SVGProps } from 'react';

export const CalculatorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
    fill="none"
    {...props}
  >
    <g fill="#333" opacity={0.5}>
      <path d="M19.164 6.707a.886.886 0 0 0 .875-.875v-1.75a.875.875 0 0 0-1.75 0v1.75a.886.886 0 0 0 .875.875Z" />
      <path d="M25 3.5A3.5 3.5 0 0 0 21.5 0h-14A3.5 3.5 0 0 0 4 3.5v21A3.5 3.5 0 0 0 7.5 28h14a3.5 3.5 0 0 0 3.5-3.5v-21ZM14.5 16.333a1.166 1.166 0 1 1 0 2.333 1.166 1.166 0 0 1 0-2.333Zm-1.167-4.083a1.167 1.167 0 1 1 2.334 0 1.167 1.167 0 0 1-2.334 0Zm6.417 9.625a.875.875 0 0 1 0 1.75h-6.417a.875.875 0 1 1 0-1.75h6.417Zm0-3.208a1.167 1.167 0 1 1 0-2.333 1.167 1.167 0 0 1 0 2.333Zm0-5.25a1.167 1.167 0 1 1 0-2.333 1.167 1.167 0 0 1 0 2.333Zm-10.5 5.25a1.167 1.167 0 1 1 0-2.334 1.167 1.167 0 0 1 0 2.334Zm1.167 4.083a1.167 1.167 0 1 1-2.334 0 1.167 1.167 0 0 1 2.334 0ZM9.25 13.417a1.167 1.167 0 1 1 0-2.334 1.167 1.167 0 0 1 0 2.334ZM7.5 2.333h14A1.167 1.167 0 0 1 22.667 3.5v3.792a.292.292 0 0 1-.292.291H6.625a.292.292 0 0 1-.292-.291V3.5A1.167 1.167 0 0 1 7.5 2.333Z" />
    </g>
  </svg>
);
