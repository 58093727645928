import { SVGProps } from 'react';

export const DualNumberIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29 22"
    width={props.width || '100%'} 
    height={props.height || 'auto'}
    fill="none"
    {...props}
  >
    <path fill="#fff" d="M.5.033h28v19H.5z" />
    <rect
      width={8.998}
      height={16.038}
      x={3.703}
      y={1.514}
      fill="#E5E5E5"
      rx={2.673}
    />
    <rect
      width={8.998}
      height={16.038}
      x={16.301}
      y={1.514}
      fill="#E5E5E5"
      rx={2.673}
    />
    <path
      fill="#757575"
      d="m16.075 14.162-.012 2.24c0 .858-.217 1.724-.65 2.598-.425.874-.966 1.59-1.624 2.146l-1.74-.893c.193-.356.383-.712.568-1.067.186-.356.34-.747.464-1.172.124-.426.186-.909.186-1.45v-2.402h2.808Z"
    />
  </svg>
);
